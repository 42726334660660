import React from "react"
import Navigation from "../components/navigationModule"
import SEO from "../components/seo"
import Footer from "../components/footer"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Styles from "../components/services.module.css"
import get from 'lodash/get'

let services = 0;

class Services extends React.Component {

    constructor(props) {
        super(props);
        let serviceItemsList = get(this, 'props.data.allContentfulServiceItem');
        console.log('serviceItemsList', serviceItemsList);
        // console.log('serviceItemsList.nodes', serviceItemsList.nodes);
        this.postContractServices = serviceItemsList.nodes.filter(item => item.type === 'post-contract');
        this.preContractServices = serviceItemsList.nodes.filter(item => item.type === 'pre-contract');
        this.projectManagementServices = serviceItemsList.nodes.filter(item => item.type === 'project_management');

        console.log('preContractServices', this.preContractServices);
        console.log('postContractServices', this.postContractServices);
        console.log('projectManagementServices', this.projectManagementServices);

        console.log('serviceItemsList original', serviceItemsList);
        if (services === 0) {
            this.preContractServices = this.preContractServices.splice(0, (this.preContractServices.length / 2));// dirty hack to remove duplicates
            this.postContractServices = this.postContractServices.splice(0, (this.postContractServices.length / 2));// dirty hack to remove duplicates
            this.projectManagementServices = this.projectManagementServices.splice(0, (this.projectManagementServices.length / 2));// dirty hack to remove duplicates
            services = serviceItemsList.length;
        }
        console.log('serviceItemsList', serviceItemsList);
    }

    render() {


        let preContractServices = this.preContractServices.map((serviceItem, i) => {
            return (
                <ServiceItem key={i} serviceItem={serviceItem}/>
            );
        });

        let postContractServices = this.postContractServices.map((serviceItem, i) => {
            return (
                <ServiceItem key={i} serviceItem={serviceItem}/>
            );
        });

        let projectManagementServices = this.projectManagementServices.map((serviceItem, i) => {
            return (
                <ServiceItem key={i} serviceItem={serviceItem}/>
            );
        });

        return (
            <>
                <SEO title="Services"/>
                <Navigation/>
                <section>

                    <div className={Styles.services_bannerimage}>
                        <div className="overlay"></div>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 col-lg-12 col-sm-12">
                                    <div className={Styles.text}>
                                        <h2>services</h2>
                                        <p>
                                            what we do
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={Styles.quantity_surveying}>
                        <div className="container">
                            <h2>Quantity Surveying</h2>
                            <div className={Styles.pre_contract_wrap}>
                                <h3>Pre-contract</h3>
                                <div className="row">

                                    <div className="col-md-12 col-12 col-sm-12">
                                        <div className="col-md-12 col-sm-12 col-12">
                                            <div className="row">
                                                {preContractServices}
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div className={Styles.post_contract_wrap}>
                                <h3>Post Contract</h3>

                                <div className="row">
                                    <div className="col-md-12 col-12 col-sm-12">
                                        <div className={Styles.pre_contract}></div>
                                        <div className="col-md-12 col-sm-12 col-12">
                                            <div className="row">
                                                {postContractServices}
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div className={Styles.Project_management}>
                        <div className="container">
                            <h2>Project Management</h2>
                            <div className="row">
                                <div className="col-md-12 col-12 col-sm-12">
                                    <div className="col-md-12 col-sm-12 col-12">
                                        <div className="row">
                                            {projectManagementServices}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
                <Footer/>
            </>
        )
    }

}

export function ServiceItem(props) {

    let {serviceItem} = props;

    console.log('serviceItem.heading', serviceItem.heading);

    return (

        <div className="col-md-12 col-12 col-sm-12">
            <div className={Styles.icon_box}>

                <ul>
                    <li>{serviceItem.heading}</li>
                </ul>


            </div>
        </div>
    );

}

export default Services;
export const query = graphql`
    {
        allContentfulServiceItem {
                nodes {
                    heading
                    tags
                    type
                }
            
        }
    }
`;
